import React, { useEffect, useState } from "react";

const ChairmanNotes = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
      
        const url=process.env.REACT_APP_API_KEY
        const response = await fetch(`${url}/web-ceonote-get`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const image = `${imageKey}/ceonote/${
    companyData?.data[0] &&
    companyData?.data[0].image &&
    companyData?.data[0].image
  }`;

  return (
    <div>
      <div
        class=" page_title_light p-40"
        style={{
          background: "rgb(39, 179, 203)",
          background:
            "linear-gradient(0deg, rgba(39, 179, 203, 0.5958381887911415) 0%, rgba(15, 125, 199, 1) 75%)",
        }}
      >
        <div class="container ">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="page-title">
                <h1>CEO Note</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <img
                src={image ? image : "assets/images/img5.jpeg"}
                style={{ borderRadius: "10px" }}
              />
              {/* {image  && <img src={require(`${image}`)} alt="blog_img3" />} */}
            </div>
            <div class="col-md-9">
              <div class="blog_post">
                {/* <div class="">
                  <p>
                    I am pleased to introduce our company{" "}
                    <b>MAROO STEELS PVT LTD</b> as a leading steel sourcing
                    organization. Growing at a fast pace since its inception in
                    the year 1956. We are proud of our strategic achievement as
                    a leading player in the global arena and as an important
                    sourcing agent across the globe to over 95 countries spread
                    across different parts of the world. In a short span of
                    time, we have expanded and evolved as an integrated sourcing
                    agent with a diverse value-added product portfolio.
                  </p>
                  <p>
                    Our dedicated management team has been pushing the company's
                    growth. The synergy of the way we operate has created more
                    value for us in terms of services and customers
                    satisfaction. The continuous updation in technology,
                    advancement, process integration, and systems efficiency has
                    helped us to add more and more value to our customers. Our
                    prime motto is Customer Satisfaction and building good
                    Customer Relations. Our success depends on our customers
                    choosing us. Our strength lies in working closely with them
                    to create the exact vision.
                  </p>
                  <p>
                    {" "}
                    <b> Sunil Maroo</b>
                    <br />
                    CEO - <b>MAROO STEELS PVT LTD, INDIA</b>
                  </p>
                </div> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChairmanNotes;
