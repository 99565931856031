import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const HomePage = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);
  const [previousSlide, setPreviousSlide] = useState(null);
  const [error, setError] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${url}/web-home-page-get`);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        if (data) {
          setCompanyData(data);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${url}/web-homebanner-get`);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        if (data) {
          setBannerData(data?.data);
          setPreviousSlide(data?.data?.length - 1);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    beforeChange: function(currentSlide, nextSlide) {
      setActiveSlide(nextSlide);
      
      setPreviousSlide(nextSlide === 0 ? bannerData?.length -1 : nextSlide - 1)
      console.log("before change", previousSlide, currentSlide, nextSlide, bannerData?.length);
    }
  };

  const folderName = "home";
  const export_home_img = `${imageKey}/${folderName}/${companyData?.data[0].export_home_img}`;
  const import_home_img = `${imageKey}/${folderName}/${companyData?.data[0].import_home_img}`;
  const service_image = `${imageKey}/${folderName}/${companyData?.data[0].service_image}`;
  const about_us_video = companyData?.data[0].about_us_video;
  return (
    <>
      <div className="container">
        <article className="boxedcontainer">
          <Slider {...settings}>
            {bannerData &&
            bannerData.map((data, index) => (
                <div key={data.id} style={{height: '500px'}} >
                  <div  className = {`${index === activeSlide ?  "banner-animate" : ''} ${index === previousSlide ? 'slick-custom-previous' : ''}`}  style={{
                  minHeight:"400px",
                  height:"400px",
                  maxHeight: "500px",
                  width: "100%",
                  position: "relative",
                  backgroundImage: `url('${imageKey}/home_banner/${data.image}')`,
                  backgroundSize: 'cover',
                  backgroundRepeat: "no-repeat"
                }}>
              <div className="progress">
                <div className={index === activeSlide ?  "progress-value" : ''}></div>
              </div>
            <div  className = {'banner-txt ' + (index === activeSlide ?  "animation-txt" : '')}  dangerouslySetInnerHTML={{
                  __html: data?.content,
                }}>
            </div>          
           </div>
         </div>
      ))}
          </Slider>
        </article>
      </div>
      <div className="section">
        <div className="container p-10 pt-10">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 ">
              <h3 className="widget_title">About Us</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].about_us,
                }}
              />
              {/* <p className="text-10">
                We are a 67 years old steel import-export indenting agent in
                India handling all types of steel. We are a 3rd Generation,
                steel sourcing organization having a good business relationship
                with all the steel mills in India and can help you in procuring
                good quality steel at a very competitive price from Indian
                mills. We are selling approximately 12,500 MT of steels per
                month from Indian mills to our 300+ customers and are spread
                across 95 countries.
              </p> */}
              <div className="text-white">
                {" "}
                {/* <a href="" type="submit" className="btn btn-default btn-sm"> */}
                <Link to="/company-profile" className="btn btn-default btn-sm">
                  {" "}
                  Read More
                </Link>
                {/* </a> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6">
              <ReactPlayer
                url={about_us_video ? about_us_video : ""}
                controls
                width="550px"
                height="300px"
                muted={true}
                playing={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container p-10 pt-10">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6">
              <img src={service_image} />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 ">
              <h3 className="widget_title">Services we Offer</h3>
            
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].service,
                }}
              />
              <div className="text-white">
                
                <Link to="/service" className="btn btn-default btn-sm">
                  {" "}
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
            <div className="services-block-three">
              <a href="/import">
                <div className="padding-15px-bottom">
                  <img src={import_home_img} width="40%" />
                </div>
                <h4>Import</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].import_home,
                  }}
                />
              
                <div className="pt-10">
                  {" "}
                  <Link to="/import" className="btn btn-default btn-sm">
                    {" "}
                    Read More
                  </Link>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
            <div className="services-block-three">
              <a href="/export">
                <div className="padding-15px-bottom">
                  <img src={export_home_img} width="40%" />
                </div>
                <h4>Export</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].export_home,
                  }}
                />
                {/* <p className="">
                  Hot Rolled Steel Coils, Sheets, Plates, Cold Rolled Steel
                  Coils and Sheets in full Hard and Soft Quality
                </p> */}
                <div className="pt-10">
                  {" "}
                  {/* <button type="submit" className="btn btn-default btn-sm ">
                    Read More
                  </button> */}
                  <Link to="/export" className="btn btn-default btn-sm">
                    {" "}
                    Read More
                  </Link>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section mb-11">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14996331.854282739!2d75.829455!3d23.4166416!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1e949bdb36d%3A0x6fd0f95db6437a93!2sMaroo%20Steels%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1709795353424!5m2!1sen!2sin"
          width="100%"
          height="600px"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default HomePage;
