import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import './App.css';
import HomePage from './pages/homepage';
import Header from './components/layout/header';
import Footer from './components/layout/footer';
import CompanyProfile from './pages/company-profile';
import ChairmanNotes from './pages/chairman-notes';
import CurrentBuisness from './pages/current-bussiness';
import History from './pages/history';
import Team from './pages/team';
import Import from './pages/imports';
import Export from './pages/export';
import Services from './pages/services';
import Gallery from './pages/gallery';
import Videos from './pages/videos';
import ContactUS from './pages/contact-us';
import Support from './pages/support';
import Carrers from './pages/carrers';
import Enquiry from './pages/enquiry';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="company-profile" element={<CompanyProfile />} />
            <Route path="chairman-notes" element={<ChairmanNotes />} />
            <Route path="current-business" element={<CurrentBuisness />} />
            <Route path="history" element={<History />} />
            <Route path="team" element={<Team />} />
            <Route path="import" element={<Import />} />
            <Route path="export" element={<Export />} />
            <Route path="services" element={<Services />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="videos" element={<Videos />} />
            <Route path="contact-us" element={<ContactUS />} />
            <Route path="support" element={<Support />} />
            <Route path="careers" element={<Carrers />} />
            {/* <Route path="enquiry" element={<Enquiry />} /> */}
          </Routes>
          <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
