import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const Gallery = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;

  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0); // Initially set to 'All'

  useEffect(() => {
    fetchData();
  }, [selectedTab]); // Fetch data whenever selected tab changes

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      setCompanyData(null);


      const url=process.env.REACT_APP_API_KEY
        // const response = await fetch(`${url}getWEBGallery`);
      
      const response = await fetch(
        `${url}/web-gallery/${
          selectedTab === 0 ? "all" : tabs[selectedTab].toLowerCase()
        }`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setCompanyData(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabs = ["All", "Flat", "Long", "pipesandtubes","semi-finished","Aluminium","ferro-alloy","stainless-steel"]; // Define your tabs here

  const renderGallery = () => {
    if (loading) {
      return <p>Loading...</p>;
    }
    if (error) {
      return <p>Error: {error}</p>;
    }
    if (!companyData || !companyData.data || companyData.data.length === 0) {
      return <p>No data available.</p>;
    }


    return (
      <div className="gallery">
        {companyData.data.map((item) => (
          <figure className={`card ${item.material_type}`} style={{background:"none !important"}} key={item.id}>
            <img
              className="card__image"
              loading="lazy"
              src={`${imageKey}/gallery/${item?.image}`} // Use the actual image URL from your data
              alt=""
            />
            <figcaption className="card__caption" style={{color:"blue",backgroundColor:"white",border:"0px !important"}}>
              {item?.matrial_name}
            </figcaption>
          </figure>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div
        className="page_title_light p-40"
        style={{
          background:
            "linear-gradient(0deg, rgba(39,179,203,0.5958381887911415) 0%, rgba(15,125,199,1) 75%, rgb(39,179,203))",
        }}
      >
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-title">
                <h1>Gallery</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Filter Tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((tab, index) => (
                <Tab style={{background:tab==selectedTab ?"#5459f7":""}} key={index} label={tab==="pipesandtubes" ?"Pipe And Tubes" :tab} />
              ))}
            </Tabs>
          </Box>
          {renderGallery()}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
