import React, { useEffect, useState } from "react";

const History = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url=process.env.REACT_APP_API_KEY
        const response = await fetch(`${url}/web-history-get`);
        // const response = await fetch("http://localhost:3000/api/getWEBHistory");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  let image;
  if (companyData?.imgae_folder && companyData?.data[0].image) {
    image = `${imageKey}/${companyData?.imgae_folder}/${companyData?.data[0].image}`;
  }
  return (
    <>
      <div
        className=" page_title_light p-40"
        style={{
          background:
            "linear-gradient(0deg, rgba(39,179,203,0.5958381887911415) 0%, rgba(15,125,199,1) 75%, rgb(39,179,203))",
        }}
      >
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-title">
                <h1>History</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="blog_post">
                <div className="blog_content">
                  <h4 className="text-center p-10">MAROO STEELS PRIVATE LIMITED</h4>
                      <div
                      dangerouslySetInnerHTML={{
                        __html: companyData?.data[0].content,
                      }}
                    />
                  {/* <div className="blog_text">
                    <p>
                      MAROO STEELS PRIVATE LIMITED is one of the oldest Import
                      and Export Indenting agent in India, dealing in all kinds
                      of Iron & Steel products since 1956. Our journey began as
                      a controlled stockist appointed by the Government of
                      India. In 1977, we diversified into import indenting for
                      all kinds of iron and steel products from Europe, Korea,
                      and Japan. Gradually, we established strong connections
                      with several steel mills and steel trading companies
                      worldwide.{" "}
                    </p>

                    <p>
                      {" "}
                      In 1995, we ventured into export indenting, focusing on
                      the exports of flat-rolled and coated steel products from
                      India. Within a few years, we developed robust
                      relationships with end customers and traders across the
                      globe, including the USA, Canada, South America, Southeast
                      Asia, Gulf Countries, South Africa, Europe, Japan, and
                      Korea.
                    </p>

                    <p>
                      Throughout the decades, Maroo Steels Private Limited has
                      become a trusted platform connecting buyers and sellers.
                      Our core values of Transparency, Dedication to Excellence,
                      and Customer Service serve as the foundation upon which
                      our company is built.
                    </p>

                    <p>
                      {" "}
                      At present, we deal in all kinds of Steel products, and
                      our commitment to these core values remains unwavering. We
                      take pride in being a reliable partner in the iron and
                      steel industry, striving to provide exceptional services
                      to our valued customers worldwide.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <h4 className="text-center mb-5">MILESTONES</h4>
          <ul className="time">
            <li style={{'--accent-color':'#35dbbd'}}>
              <div className="date">1987-2002 </div>
              <div className="descr mt-3">
                Exclusively represented MANNESMANN BRAZIL for selling their
                Carbon and Alloy Pipes in India.
              </div>
            </li>
            <li style={{'--accent-color':'#FBCA3E'}}>
              <div className="date">1990-94</div>
              <div className="descr mt-3">
                Worked as Selling Agent for JINDAL STRIPS for their CRCA product
                to all Oil Companies in India.
              </div>
            </li>
            <li style={{'--accent-color':'#e167b2eb'}}>
              <div className="date">1992-99</div>
              <div className="descr mt-3">
                {" "}
                Exclusively represented CCC TRADING for imports of steel into
                India.
              </div>
            </li>
            <li style={{'--accent-color':'#18a53f'}}>
              <div className="date">1993</div>
              <div className="descr mt-3">
                Sold 60,000MT MS SLABS to JINDAL STEEL from POSCO through
                SSANGYONG CORPORATION.
              </div>
            </li>
            <li style={{ '--accent-color': '#35dbbd' }}>
              <div className="date">1994-2000 </div>
              <div className="descr mt-3">
                Exclusively represented ASIL CELIK - TURKEY for Carbon/Alloy
                Steel Rounds to India.
              </div>
            </li>
            <li style={{'--accent-color':'#FBCA3E'}}>
              <div className="date">1997-2000 </div>
              <div className="descr mt-3">
                Worked as C&F agent for ESSAR in Maharashtra.
              </div>
            </li>
            <li style={{'--accent-color':'#e167b2eb'}}>
              <div className="date">2002-03</div>
              <div className="descr mt-3">
                Started focussing on exports with secondary Galvanized.
              </div>
            </li>
            <li style={{'--accent-color':'#18a53f'}}>
              <div className="date">2004 </div>
              <div className="descr mt-3">
                Started exports of Prime material along with secondary.
              </div>
            </li>
            <li style={{'--accent-color':'#35dbbd'}}>
              <div className="date">2004 </div>
              <div className="descr mt-3">
                Gets orders for Prestigious projects such as Singapore Airport's
                T3 Terminal.
              </div>
            </li>
            <li style={{'--accent-color':'#FBCA3E'}}>
              <div className="date">2006</div>
              <div className="descr mt-3">
                {" "}
                Product Range expanded to include pre-Painted as well as
                Galvalume Steel.
              </div>
            </li>
            <li style={{'--accent-color':'#E24A68'}}>
              <div className="date">2007 </div>
              <div className="descr mt-3">
                {" "}
                We expand our operations to the Middle East with our involvement
                in major upcoming Infrastructure projects.
              </div>
            </li>
            <li style={{'--accent-color':'#06d840c4'}}>
              <div className="date">2008 </div>
              <div className="descr mt-3">
                Exports expand to 27 countries globally. Prime exports become
                close to 75% of the Co's. business.
              </div>
            </li>
            <li style={{'--accent-color':'#35dbbd'}}>
              <div className="date">2009 </div>
              <div className="descr mt-3">
                Registers growth of 15% despite global recession and lacklustre
                economic scenario.
              </div>
            </li>
            <li style={{'--accent-color':'#FBCA3E'}}>
              <div className="date">2010</div>
              <div className="descr mt-3">
                Entered Central and South American market.
              </div>
            </li>
            <li style={{'--accent-color':'#E24A68'}}>
              <div className="date">2011</div>
              <div className="descr mt-3">
                We break the half century barrier and increase our presence to
                58 countries now.
              </div>
            </li>
            <li style={{'--accent-color':'#06d840c4'}}>
              <div className="date">2011</div>
              <div className="descr mt-3">
                Prime exports touch 90% of the Co's. business in Flat Coated
                Steel products.
              </div>
            </li>
            <li style={{'--accent-color':'#41516C'}}>
              <div className="date">2012</div>
              <div className="descr mt-3">
                MAROO STEELS PVT LTD has now presence in 83 countries globally.
              </div>
            </li>
            <li style={{'--accent-color':'#FBCA3E'}}>
              <div className="date">2013</div>
              <div className="descr mt-3">
                Sales volumes touches 1 million tonnes since the inception of
                the Cos. exporting business.
              </div>
            </li>
            <li style={{'--accent-color':'#E24A68'}}>
              <div className="date">2014</div>
              <div className="descr mt-3"> Sold 50000 MT to USA market.</div>
            </li>
            <li style={{'--accent-color':'#06d840c4'}}>
              <div className="date">2015</div>
              <div className="descr mt-3">Exported Rebars to USA</div>
            </li>
            <li style={{'--accent-color':'#41516C'}}>
              <div className="date">2016</div>
              <div className="descr mt-3">
                Expanded our business for Mexican market.
              </div>
            </li>
            <li style={{'--accent-color':'#FBCA3E'}}>
              <div className="date">2017</div>
              <div className="descr mt-3">
                {" "}
                Started exporting Agro Products from India.
              </div>
            </li>
            <li style={{'--accent-color':'#E24A68'}}>
              <div className="date">2018 </div>
              <div className="descr mt-3">
                Started exporting Chemicals from India.
              </div>
            </li>
            <li style={{'--accent-color':'#06d840c4'}}>
              <div className="date">2019 </div>
              <div className="descr mt-3">
                Sales volume crossed 1.5 million tonnes since inception.
              </div>
            </li>
            <li style={{'--accent-color':'#41516C'}}>
              <div className="date">2020 </div>
              <div className="descr mt-3">
                We now have presence in 95 countries globally. In 2020 we also
                started exporting Long Products from India.
              </div>
            </li>
            <li style={{'--accent-color':'#FBCA3E'}}>
              <div className="date">2021 - 2022</div>
              <div className="descr mt-3">
                We expanded our exports of Long products to the South East Asia
                market and successfully shipped approximately 35,000 tons of
                High Carbon wire rods, alongside our regular export products.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default History;
