import React, { useEffect, useState } from "react";

const Support = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url=process.env.REACT_APP_API_KEY
        const response = await fetch(`${url}/web-support-get`);
        // const response = await fetch("http://localhost:3000/api/getWEBSupport");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div
        class=" page_title_light p-40"
        style={{
          background:
            "linear-gradient(0deg, rgba(39,179,203,0.5958381887911415) 0%, rgba(15,125,199,1) 75%, rgb(39,179,203))",
        }}
      >
        <div class="container ">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="page-title">
                <h1>Support</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="blog_post">
                <div class="blog_content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].content,
                  }}
                />
                  {/* <div class="blog_text">
                    <p>
                      We provide accurate information to our clients ensuring
                      they procure ideal material suitable to their
                      requirements. We advise our clients on the ideal range of
                      products suiting their Steel as well as monetary
                      requirements.
                    </p>
                    

                    <p>
                      The focus is on continuous improvement of timely, accurate
                      product supply and also provide comprehensive and timely
                      technical and commercial information which is relevant to
                      your applications. SUPPORT is provided 24/7 depending on
                      their needs by a dedicated relationship manager.
                      <br />
                      <br />
                      <a href="contact-us.html">
                        {" "}
                        Contact us for more details.
                      </a>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
