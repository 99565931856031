import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Import = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [importBannerData, setImportBannerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${url}/web-importdata-get`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataBannerData = async () => {
      try {
        const url = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${url}/web-importbanner-get`);
        // const response = await fetch(
        //   "http://localhost:3000/api/getWEBImportBanner"
        // );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setImportBannerData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchDataBannerData();
  }, []);

  const coated_steel_image_column = `${imageKey}/import_data/${companyData?.data[0].coated_steel_image_column}`;
  const flat_products_image_column = `${imageKey}/import_data/${companyData?.data[0].flat_products_image_column}`;
  const long_products_image_column = `${imageKey}/import_data/${companyData?.data[0].long_products_image_column}`;
  const raw_material_image_column = `${imageKey}/import_data/${companyData?.data[0].raw_material_image_column}`;
  const scrap_image_column = `${imageKey}/import_data/${companyData?.data[0].scrap_image_column}`;
  const seamless_pipe_image_column = `${imageKey}/import_data/${companyData?.data[0].seamless_pipe_image_column}`;
  const secondary_product_image_column = `${imageKey}/import_data/${companyData?.data[0].secondary_product_image_column}`;
  const semiproductimage_column = `${imageKey}/import_data/${companyData?.data[0].semiproductimage_column}`;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <div className="banner_section">
        {importBannerData && importBannerData !== null && (
          <Slider {...settings}>
            {importBannerData.data.map((data) => (
              <div
                key={data.id} // Assuming each banner data has a unique ID
                className="item active background_bg"
                // style={{
                //   // backgroundImage: `url(${imageKey}/import_banner/${data.image})`,
                //   height: "300px",
                // }}
              >
                <img  height="300px" width={"100%"} src={`${imageKey}/import_banner/${data.image}`} />
                <div className="banner_slide_content">
                  <div className="container text-center">
                    <div className="row">
                      <div className="col-xl-12 col-md-8 col-sm-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>

      <div className="text-black p-40  mt-double">
        <div className="container ">
          <div className="row text-center">
            <div className="col-md-12">
              <div className="mt-5">
                <h1>Imports</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-8">
              <h4 className="heading2">Semi Products</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].semi_products_column,
                }}
              />
            </div>
            <div className="col-md-4">
              <img src={semiproductimage_column} />
            </div>
          </div>
        </div>
      </div>
      <div className="section bg_gray">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h4 className=" heading2">Flat Products</h4>

              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].flat_products_column,
                }}
              />
              <br />
              
            </div>
            <div className="col-md-4">
              <img src={flat_products_image_column} />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row ">
            <div className="col-md-8">
              <h4 className=" heading2">Coated Steel </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].coated_steel_column,
                }}
              />
            </div>
            <div className="col-md-4">
              <img src={coated_steel_image_column} />
            </div>
          </div>
        </div>
      </div>
      <div className="section bg_gray">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h4 className=" heading2">Long Products</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].long_products_column,
                }}
              />
              {/* <h5>
                Hot Rolled Rounds in Carbon and Alloy Steel grade (S20C, S45C,
                S50C, SAE 4140, SAE 4130, 20MnCr5, SAE 52100 etc)
              </h5>
              <ul className="blog_meta">
                <li>
                  <i className="ti-arrow-circle-right design1"></i>Sizes: 100 to
                  450 mm
                </li>
                <li>
                  <i className="ti-arrow-circle-right design1"></i>Length: 5800
                  to 9000 mm
                </li>
              </ul>
              <br />
              <h5>
                Wire Rods in Low, Medium, High carbon as well as Alloy Steels
                grades
              </h5>
              <ul className="blog_meta">
                <li>
                  <i className="ti-arrow-circle-right design1"></i>Dia: 5.5 to
                  32 mm
                </li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={long_products_image_column} />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row ">
            <div className="col-md-8">
              <h4 className="heading2">Seamless Pipes </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].seamless_pipe_column,
                }}
              />
              {/* <h5>Line Pipes</h5>
              <ul className="blog_meta">
                <li>
                  <i className="ti-arrow-circle-right design1"></i>A 106Gr B/API
                  5L Gr B/ASTM A 53
                </li>
                <li>
                  <i className="ti-arrow-circle-right design1"></i>1” to 24” x
                  Sch 20/30/40/80
                </li>
              </ul>
              <br />
              <h5>Seamless Pipes - Mainly Ball Bearing Grade </h5>
              <ul className="blog_meta">
                <li>
                  <i className="ti-arrow-circle-right design1"></i>Grade: SAE
                  52100 or DIN100cr6 or SUJ 2
                </li>
                <li>
                  {" "}
                  <i className="ti-arrow-circle-right design1"></i>Size:
                </li>
                <li>OD - 25mm to 100mm </li>
                <li>WT - 3mm to 12mm</li>
                <li>Length: Minimum 1 meter </li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={seamless_pipe_image_column} />
            </div>
          </div>
        </div>
      </div>
     
      <div className="section">
        <div className="container">
          <div className="row ">
            <div className="col-md-8">
              <h4 className=" heading1">Raw Material</h4>
              {/* <ul className="blog_meta">
                <li>
                  <i className="ti-arrow-circle-right design1"></i>Ferro Alloys{" "}
                </li>
              </ul> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].raw_material_column,
                }}
              />
            </div>
            <div className="col-md-4">
              <img src={raw_material_image_column} />
            </div>
          </div>
        </div>
      </div>
      <div className="section bg_gray">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h4 className=" heading1">SCRAP</h4>
              {/* <ul className="blog_meta" />
              <li>
                <i className="ti-arrow-circle-right design1"></i>Alloy Steel
                Scrap
              </li>
              <li>
                <i className="ti-arrow-circle-right design1"></i>Alloy Steel
                Scrap
              </li>
              <li>
                <i className="ti-arrow-circle-right design1"></i>Tool Steel
                Scrap
              </li>
              <li>
                <i className="ti-arrow-circle-right design1"></i>Stainless steel
              </li> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].scrap_column,
                }}
              />
            </div>
            <div className="col-md-4">
              <img src={scrap_image_column} />
            </div>
          </div>
        </div>
      </div>
      <div className="section bg_gray">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h4 className="heading2">Secondary Products</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].secondary_products_column,
                }}
              />
            </div>
            <div className="col-md-4">
              <img src={secondary_product_image_column} alt="secon" width={"350px"} height={"350px"}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Import;
 {/* <div className="banner_section ">
        <div
          className="carousel_slider owl-carousel"
          data-loop="true"
          data-items="1"
          data-nav="true"
          data-autoplay="true"
          data-smart-speed="100"
        >
          {importBannerData && importBannerData!==null &&
            importBannerData.data.length > 0 &&
            importBannerData?.data?.map((data) => {
              return (
                <div
                  className="item active background_bg"
                  // data-img-src="assets/images/import2.jpg"
                  style={{
                    backgroundImage: `url(${imageKey}/import_banner/${data?.image})`, 
                    height: "300px",
                  }}
                  // style={{height: "300px"}}
                >
                  <div className="banner_slide_content">
                    <div className="container text-center">
                      <div className="row">
                        <div className="col-xl-12 col-md-8 col-sm-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div> */}