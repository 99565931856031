import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

const ContactUS = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${url}/web-contactus-get`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div
        class=" page_title_light p-40"
        style={{
          background:
            "linear-gradient(0deg, rgba(39,179,203,0.5958381887911415) 0%, rgba(15,125,199,1) 75%, rgb(39,179,203))",
        }}
      >
        <div class="container ">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="page-title">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        // style={{ backgroundColor: "#f0f0f0" }}
        // className="bg-gray"
        id="fda_product_tile"
      >
        <div class="container">
          <h2 class="text-red">Our Management Team</h2>

          <div class="contact-box" style={{ display: "flex !important" }}>
            <div
              class="row"
              style={{
                justifyContent: "center",
                display: "dlex",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              {companyData &&
                companyData.data
                  .filter(
                    (data) =>
                      data.designation.trim().toLowerCase() === "ceo" ||
                      data.designation.trim().toLowerCase() === "director"
                  )
                  .map((data, index) => (
                    <div class="col-md-6 py-5 ">
                      <img
                        src={`${imageKey}/contact_profile_pic/${data?.profile_image}`}
                        alt=""
                        className="mt-5 custom-image-style"
                      />
                      {/* <img src="assets/images/t1.png" alt="" class="mt-5" /> */}

                      <div
                        class="food_tile"
                        style={{ alignItems: "center", textAlign: "center" }}
                      >
                        <h3 class="food_name">{data?.full_name}</h3>
                        <h4 class="contact-h2">{data?.designation}</h4>
                        <div
                          class="mb-3"
                          style={{
                            alignItems: "center",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column ",
                            justifyContent: "center",
                          }}
                        >
                          <h6>
                            <a href={`mailto:${data?.email}`} target="_blank">
                              <i className="ti-email"></i>&nbsp; &nbsp;
                              {data?.email}
                            </a>
                          </h6>

                          <h6>
                            <i class="bi bi-telephone-forward"></i>&nbsp;
                            {data?.mobile_1}
                          </h6>
                          <h6>
                            <i class="ti-mobile"></i>
                            &nbsp;{data?.mobile_2}
                          </h6>
                          <div class="flex-container1">
                            <div class="siocn">
                              <a
                                target="_blank"
                                href={
                                  index === 0
                                    ? "https://api.whatsapp.com/send?phone=919820637667&text=Got%20your%20reference%20from%20your%20*Digital%20Business%20Card*.%20I%20want%20to%20know%20more%20about%20your%20services."
                                    : "https://api.whatsapp.com/send?phone=919820637668"
                                }
                              >
                                <img
                                  src="assets/images/whatsapp.png"
                                  width="30px"
                                  style={{ objectFit: "contain" }}
                                />
                              </a>
                            </div>
                            <div class="siocn">
                              <a
                                href={
                                  index === 0
                                    ? "https://t.me/Sunil_Maroo"
                                    : "https://t.me/+919820637668"
                                }
                              >
                                <img
                                  src={
                                    index === 0
                                      ? "assets/images/telegram.png"
                                      : "assets/images/telegram.png"
                                  }
                                  width="30px"
                                  style={{ objectFit: "contain" }}
                                />
                              </a>
                            </div>
                            {index === 0 && (
                              <>
                                <div class="siocn">
                                  <a href="https://www.linkedin.com/in/sunil-maroo-92213917b/">
                                    <img
                                      src="assets/images/linkedin.png"
                                      width="30px"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </a>
                                </div>
                                <div class="siocn">
                                  <a href="https://chats.viber.com/add?number=919820637667">
                                    <img
                                      src="assets/images/viber.png"
                                      width="30px"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </a>
                                </div>
                              </>
                            )}
                            <div class="siocn">
                              <a href="https://join.skype.com/invite/lOEPfCblXB01">
                                <img
                                  src="assets/images/skype.png"
                                  width="30px"
                                  style={{ objectFit: "contain" }}
                                />
                              </a>
                            </div>
                            {index === 0 && (
                              <>
                                <div class="siocn">
                                  <a href="https://www.kakaocorp.com/page/service/service/KakaoTalk">
                                    <img
                                      src="assets/images/kakaotalk.png"
                                      width="30px"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </a>
                                </div>
                                <div class="siocn">
                                  <a href="https://u.wechat.com/kHAzxEaRaeUrYHo1vgwEX9Y">
                                    <img
                                      src="assets/images/wechat.png"
                                      width="30px"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </a>
                                </div>
                                <div class="siocn">
                                  <a href="https://line.me/ti/p/5gbycjtxVk">
                                    <img
                                      src="assets/images/line.png"
                                      width="30px"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </a>
                                </div>
                                <div class="siocn">
                                  <a href="http://zaloapp.com/qr/p/vsettx7g6339">
                                    <img
                                      src="assets/images/zalo.png"
                                      width="30px"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </a>
                                </div>
                              </>
                            )}
                          </div>
                          <p class="">
                            <a
                              href={
                                index == 0
                                  ? "/assets/pdf/Digital Visiting Card .pdf"
                                  : "/assets/pdf/Sujit Pal - Digital Visiting Card.pdf"
                              }
                              target="_blank"
                            >
                              <img
                                src="assets/images/id-card.png"
                                width="30px"
                                style={{ objectFit: "contain" }}
                              />{" "}
                              &nbsp;VCARD
                            </a>
                          </p>

                          {/* <h6>
                            <a href="#">
                              <i class="bi bi-skype">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-skype"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                                </svg>
                              </i>
                            </a>
                            &nbsp; &nbsp;maroosteels
                          </h6> */}
                          {/* <h6>
                            <a href="https://t.me/+919820637668">
                              <i class="bi bi-telegram"></i>&nbsp; +91
                              9820637667
                            </a>
                          </h6> */}
                        </div>
                        {/* <span class="food_detail">
                            Sunil Maroo (CEO) handles this department effectively
                            for us. He holds the responsibility for the corporate
                            planning and generation of future strategy. some of
                            his responsibilities include monitoring developments
                            to ensure that they are running smoothly and ensuring
                            appropriate strategies are being executed with the
                            effeciency MAROO STEELS PVT LTD is proud of.
                          </span> */}
                      </div>
                    </div>
                  ))}
              {/* <div class="col-md-10 col-sm-12 col-12 p-10 "> */}
              {/* </div> */}
            </div>
          </div>

          <div className="container">
            <Typography variant="h2" fontSize={"2rem"} className="text-red">
              Our Team Members
            </Typography>

            <div className="contact-box" style={{ display: "flex !important" }}>
              <Grid container justifyContent="center" textAlign={"center"}>
                {companyData &&
                  companyData.data
                    .filter(
                      (data) =>
                        data.designation.trim().toLowerCase() !== "ceo" &&
                        data.designation.trim().toLowerCase() !== "director"
                    )
                    .map((data, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <div
                          className="col-md-12 py-1"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={`${imageKey}/contact_profile_pic/${data?.profile_image}`}
                            alt=""
                            className="mt-5 custom-image-style"
                          />
                        </div>
                        <div className="food_tile">
                          <h3 class="food_name">{data?.full_name}</h3>
                          <h4 class="contact-h2">{data?.designation}</h4>
                          <div className="mb-3 ">
                            <a href={`mailto:${data?.email}`} target="_blank">
                              {/* <Typography> */}
                              <i className="ti-email"></i>&nbsp; &nbsp;{" "}
                              {data?.email}
                              {/* </Typography> */}
                            </a>
                            <h6
                              style={{
                                textAlign: "center",
                                paddingTop: "10px",
                                fontWeight: 400,
                              }}
                            >
                              <i class="bi bi-telephone-forward"></i>&nbsp;
                              {data?.mobile_1}
                            </h6>
                            {/* <Typography> */}
                            {data?.mobile_2 && data?.mobile_2 !== "-" && (
                              <a
                                target="_blank"
                                href={`https://api.whatsapp.com/send?phone=${data?.mobile_2.replace(/[+\s]/g, "")}`}
                              >
                                <i className="bi bi-whatsapp"></i>
                                &nbsp;{data?.mobile_2}
                              </a>
                            )}
                            {/* </Typography> */}
                            {/* <Typography>
                              <a href="#">
                                <i className="bi bi-skype">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-skype"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                                  </svg>
                                </i>
                              </a>
                              &nbsp; &nbsp;maroosteels
                            </Typography> */}
                            {/* <Typography>
                              <a href="https://t.me/+919820637668">
                                <i className="bi bi-telegram"></i>&nbsp; +91
                                9820637667
                              </a>
                            </Typography> */}
                          </div>
                          {/* <span className="food_detail">
                      Sunil Maroo (CEO) handles this department effectively for us.
                      He holds the responsibility for the corporate planning and
                      generation of future strategy. Some of his responsibilities
                      include monitoring developments to ensure that they are
                      running smoothly and ensuring appropriate strategies are being
                      executed with the efficiency MAROO STEELS PVT LTD is proud of.
                    </span> */}
                        </div>
                      </Grid>
                    ))}
              </Grid>
            </div>
          </div>

          {/* <div class="contact-box">
            <div class="row">
              <div class="col-md-2 py-5 text-center">
                <img src="assets/images/t1.png" alt="" class="mt-5" />
              </div>
              <div class="col-md-10 col-sm-12 col-12 p-10 ">
                <div class="food_tile">
                  <span class="food_name">Sunil Maroo</span>
                  <h5 class="contact-h2">CEO</h5>
                  <div class="mb-3 ">
                    <h6>
                      <i class="ti-email"></i>&nbsp;
                      &nbsp;sunil.maroo@maroosteels.com
                    </h6>
                    <h6>
                      <i class="ti-mobile"></i>&nbsp; &nbsp;+91 22 40321811
                    </h6>
                    <h6>
                      <a href="https://api.whatsapp.com/send?phone=919820637667&text=Got%20your%20reference%20from%20your%20*Digital%20Business%20Card*.%20I%20want%20to%20know%20more%20about%20your%20services.">
                        <i class="bi bi-whatsapp"></i>
                      </a>{" "}
                      &nbsp;+91 9820637667
                    </h6>
                    <h6>
                      <a href="#">
                        <i class="bi bi-skype">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-skype"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                          </svg>
                        </i>
                      </a>
                      &nbsp; &nbsp;maroosteels
                    </h6>
                    <h6>
                      <a href="https://t.me/+919820637668">
                        <i class="bi bi-telegram"></i>&nbsp; +91 9820637667
                      </a>
                    </h6>
                  </div>
                  <span class="food_detail">
                    Sunil Maroo (CEO) handles this department effectively for
                    us. He holds the responsibility for the corporate planning
                    and generation of future strategy. some of his
                    responsibilities include monitoring developments to ensure
                    that they are running smoothly and ensuring appropriate
                    strategies are being executed with the effeciency MAROO
                    STEELS PVT LTD is proud of.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-box mb-5">
            <div class="row">
              <div class="col-md-2 py-5 text-center">
                <img src="assets/images/t2.png" alt="" class="mt-5" />
              </div>
              <div class="col-md-10 col-sm-12 col-12 p-10 ">
                <div class="food_tile">
                  <span class="food_name">Sujit Pal</span>
                  <h5 class="contact-h2">Director</h5>
                  <div class="mb-3">
                    <h6>
                      <i class="ti-email"></i>&nbsp;
                      &nbsp;sujit.pal@maroosteels.com
                    </h6>
                    <h6>
                      <i class="ti-mobile"></i>&nbsp; &nbsp;+91 22 40321814
                    </h6>
                    <h6>
                      <a href="#">
                        <i class="bi bi-whatsapp"></i>
                      </a>
                      &nbsp; &nbsp;+91 9820637668
                    </h6>
                    <h6>
                      <a href="#">
                        <i class="bi bi-skype">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-skype"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                          </svg>
                        </i>
                      </a>
                      &nbsp; &nbsp;sujeetpal
                    </h6>
                    <h6>
                      <i class="bi bi-telegram"></i>&nbsp; &nbsp;+91 9820637668
                    </h6>
                  </div>
                  <span class="food_detail">
                    Mr. Sujit Pal, he in-charge of this department, monitors the
                    company's performance and efficiency to make improvements
                    and address problems.He understands and develops strategic
                    objectives, designs operations of products, services and
                    processes, and works on improving performance of operation.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h2 class="text-red">Export Head </h2>
          <div class="contact-box mb-5">
            <div class="row">
              <div class="col-md-2 py-5 text-center">
                <img
                  src="assets/images/Tarrak-Raawwal-photo.jpeg"
                  alt=""
                  class="mt-3"
                />
              </div>
              <div class="col-md-10 col-sm-12 col-12 p-10 ">
                <div class="food_tile">
                  <span class="food_name">Tarrak Raawwal </span>
                  <div class="mb-3">
                    <h6>
                      <i class="ti-home"></i>&nbsp; &nbsp;Department: Sales
                    </h6>
                    <h6>
                      <i class="ti-email"></i>&nbsp;
                      &nbsp;taarak.rawal@maroosteels.com
                    </h6>
                    <h6>
                      <i class="ti-mobile"></i>&nbsp; &nbsp;+91 22 40321818{" "}
                    </h6>
                    <h6>
                      <a href="#">
                        <i class="bi bi-whatsapp"></i>
                      </a>{" "}
                      &nbsp;+91 7506659235
                    </h6>
                    <h6>
                      <a href="#">
                        <i class="bi bi-skype">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-skype"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                          </svg>
                        </i>
                      </a>
                      &nbsp; taarak.maroosteels
                    </h6>
                  </div>
                  <span class="food_detail">
                    Our Sales team aligns the whole organization with the market
                    and thus, improves the business performance. It consists of
                    an expert team of people developing each continent with
                    precision. At MAROO STEELS PVT LTD, Mr. Tarrak Raawwal
                    (Export Head - Africa, Asia, Canada, USA, South & Central
                    America, Australia, New Zealand), Mr. Dinesh Nayak (Export
                    Head - Europe, Middle & Far East).
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h2 class="text-red ">Logistics Manager </h2>
          <div class="row contact-box">
            <div class="col-md-2 col-sm-12 col-12">
              <img
                src="assets/images/sunil-dabholkar-Logistics.jpeg"
                alt=""
                class="mt-5"
              />
            </div>
            <div class="col-md-10 col-sm-12 col-12 p-10">
              <div class="food_tile">
                <span class="food_name">Sunil Dabholkar </span>
                <div class="mb-3">
                  <h6>
                    <i class="ti-home"></i>&nbsp; &nbsp;Department: Logistics
                  </h6>
                  <h6>
                    <i class="ti-email"></i>&nbsp;
                    &nbsp;sunil.dabholkar@maroosteels.com
                  </h6>
                  <h6>
                    <i class="ti-mobile"></i>&nbsp; &nbsp;+91 22 40321827{" "}
                  </h6>
                  <h6>
                    <a href="#">
                      <i class="bi bi-whatsapp"></i>
                    </a>{" "}
                    &nbsp;+91 9930260288{" "}
                  </h6>
                  <h6>
                    <a href="#">
                      <i class="bi bi-skype">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-skype"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                        </svg>
                      </i>
                    </a>
                    &nbsp; &nbsp;sdabholkar
                  </h6>
                </div>
                <span class="food_detail">
                  Our Logistics team provides complete support from the time of
                  placing the order till the time of order to delivery. We have
                  a dedicated team of qualified individuals who handle and
                  update on the exact position of clients order. Mr. Amol Chavan
                  (Logistics Manager) and Mr. Sunil Dabholkar (Logistics
                  Manager) are the in-charge of this department. They optimize
                  the logistics services to ensure that there is no deadlock
                  situation between the services and products that are being
                  waited for and their availability.
                </span>
              </div>
            </div>
          </div>
          <div class="row contact-box mb-5">
            <div class="col-md-2 col-sm-12 col-12">
              <img
                src="assets/images/amol-chavan-Logistics.png"
                alt=""
                class="mt-5"
              />
            </div>
            <div class="col-md-10 col-sm-12 col-12 p-10 ">
              <div class="food_tile">
                <span class="food_name">Amol Chavan</span>
                <div class="mb-3">
                  <h6>
                    <i class="ti-home"></i>&nbsp; &nbsp;Department:Logistics
                  </h6>
                  <h6>
                    <i class="ti-email"></i>&nbsp;
                    &nbsp;amol.chavan@maroosteels.com
                  </h6>
                  <h6>
                    <i class="ti-mobile"></i>&nbsp;&nbsp;+91 22 40321822
                  </h6>
                  <h6>
                    <a href="#">
                      <i class="bi bi-whatsapp"></i>
                    </a>
                    &nbsp; &nbsp;+91 9930260283
                  </h6>
                  <h6>
                    <a href="#">
                      <i class="bi bi-skype">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-skype"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                        </svg>
                      </i>
                    </a>
                    &nbsp;&nbsp;amol.maroosteels
                  </h6>
                </div>
                <span class="food_detail">
                  Our Logistics team provides complete support from the time of
                  placing the order till the time of order to delivery. We have
                  a dedicated team of qualified individuals who handle and
                  update on the exact position of clients order. Mr. Amol Chavan
                  (Logistics Manager) and Mr. Sunil Dabholkar (Logistics
                  Manager) are the in-charge of this department. They optimize
                  the logistics services to ensure that there is no deadlock
                  situation between the services and products that are being
                  waited for and their availability.
                </span>
              </div>
            </div>
          </div>
          <h2 class="text-red">International Finance</h2>
          <div class="contact-box mb-5">
            <div class="row">
              <div class="col-md-2 py-5 text-center">
                <img
                  src="assets/images/Joseph-Fernandes.jpeg"
                  alt=""
                  class="mt-3"
                />
              </div>
              <div class="col-md-10 col-sm-12 col-12 p-10 ">
                <div class="">
                  <span class="food_name">Joseph Fernandes</span>
                  <div class="mb-3">
                    <h6>
                      <i class="ti-home"></i>&nbsp;
                      &nbsp;Department:International Finance
                    </h6>
                    <h6>
                      <i class="ti-email"></i>&nbsp;
                      &nbsp;joseph.fernandes@maroosteels.com
                    </h6>
                    <h6>
                      <i class="ti-mobile"></i>&nbsp; &nbsp;+91 22 40321833
                    </h6>
                    <h6>
                      <a href="#">
                        <i class="bi bi-whatsapp"></i>
                      </a>{" "}
                      &nbsp;&nbsp;+91 7506659235
                    </h6>
                    <h6>
                      <a href="#">
                        <i class="bi bi-skype">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-skype"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                          </svg>
                        </i>
                      </a>
                      &nbsp; &nbsp;taarak.maroosteels
                    </h6>
                  </div>
                  <span class="food_detail">
                    International finance is one of the most important parts of
                    our process. This team under the guidance of Mr. Joseph
                    Fernandes manages all the financial related matters of your
                    dealings with Maroo Steels Pvt Ltd, having a vast experience
                    our team ensures that all documents and formalities are in
                    place, and we pro-actively assist you in ensuring that
                    things stay smooth even during rough waters.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h2 class="text-red ">Accounts </h2>
          <div class="row contact-box ">
            <div class="col-md-2 col-sm-12 col-12">
              <img src="assets/images/Ashok_Kankani.jpg" alt="" class="mt-5" />
            </div>
            <div class="col-md-10 col-sm-12 col-12 p-10">
              <div class="food_tile">
                <span class="food_name">Ashok Kankani</span>
                <div class="mb-3">
                  <h6>
                    <i class="ti-home"></i>&nbsp; &nbsp;Department:Accounts
                  </h6>
                  <h6>
                    <i class="ti-email"></i>&nbsp;
                    &nbsp;accounts@maroosteels.com
                  </h6>
                  <h6>
                    <i class="ti-mobile"></i>&nbsp; &nbsp;Tel: +91 22 40321826
                  </h6>
                </div>
                <span class="food_detail">
                  Accounts management at Maroo Steels PVT LTD is seamless and
                  lay emphasis on compliance. This team is guided by the highest
                  standards of account management and are fully aware in the
                  processes and compliance that you may need assistance with.
                  They pro-actively take steps and assist you in ensuring that
                  things stay smooth even during rough waters.
                </span>
              </div>
            </div>
          </div>

          <div class="row contact-box mb-5">
            <div class="col-md-2 col-sm-12 col-12">
              <img src="assets/images/woman.png" alt="" class="mt-5" />
            </div>
            <div class="col-md-10 col-sm-12 col-12 p-10">
              <div class="food_tile">
                <span class="food_name">Pooja Parab</span>
                <div class="mb-3">
                  <h6>
                    <i class="ti-home"></i>&nbsp; &nbsp;Department:Accounts
                  </h6>
                  <h6>
                    <i class="ti-email"></i>&nbsp;
                    &nbsp;accounts@maroosteels.com
                  </h6>
                  <h6>
                    <i class="ti-mobile"></i>&nbsp; &nbsp;Tel: +91 22 40321824
                  </h6>
                </div>
                <span class="food_detail">
                  Accounts management at Maroo Steels PVT LTD is seamless and
                  lay emphasis on compliance. This team is guided by the highest
                  standards of account management and are fully aware in the
                  processes and compliance that you may need assistance with.
                  They pro-actively take steps and assist you in ensuring that
                  things stay smooth even during rough waters.
                </span>
              </div>
            </div>
          </div>
          <h2 class="text-red">Sourcing and Business Development</h2>
          <div class="row contact-box mb-5">
            <div class="col-md-2 col-sm-12 col-12">
              <img src="assets/images/t3.png" alt="" class="mt-4" />
            </div>
            <div class="col-md-10 col-sm-12 col-12 p-10">
              <div class="food_tile">
                <span class="food_name">Mrs. Sonam Bane</span>
                <div class="mb-3">
                  <h6>
                    <i class="ti-home"></i>&nbsp; &nbsp;Department:Sourcing and
                    Business Development
                  </h6>
                  <h6>
                    <i class="ti-email"></i>&nbsp; &nbsp;trading@maroosteels.com
                  </h6>
                  <h6>
                    <i class="ti-mobile"></i>&nbsp; &nbsp;Tel: +91 22 40321820
                  </h6>
                  <h6>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-skype"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 0 1 6.02 2.118 7.37 7.37 0 0 1 2.167 5.215q0 .517-.072 1.026a4.66 4.66 0 0 1 .6 2.281 4.64 4.64 0 0 1-1.37 3.294A4.67 4.67 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 0 1-6.114-2.107A7.37 7.37 0 0 1 .529 8.035q0-.545.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 0 0 1.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 0 1-1.048-.206 11 11 0 0 1-.532-.253 1.3 1.3 0 0 0-.587-.15.72.72 0 0 0-.501.176.63.63 0 0 0-.195.491.8.8 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.1 5.1 0 0 0 2.212.419 4.6 4.6 0 0 0 1.624-.265 2.3 2.3 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 0 0-.279-1.101 2.5 2.5 0 0 0-.772-.792A7 7 0 0 0 8.486 7.3a1 1 0 0 0-.145-.058 18 18 0 0 1-1.013-.447 1.8 1.8 0 0 1-.54-.387.73.73 0 0 1-.2-.508.8.8 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.7.7 0 0 0 .18-.496.72.72 0 0 0-.17-.476 1.4 1.4 0 0 0-.556-.354 3.7 3.7 0 0 0-.708-.183 6 6 0 0 0-1.022-.078 4.5 4.5 0 0 0-1.536.258 2.7 2.7 0 0 0-1.174.784 1.9 1.9 0 0 0-.45 1.287c-.01.37.076.736.25 1.063" />
                      </svg>
                    </a>
                    &nbsp; &nbsp;sonam.bane
                  </h6>
                </div>
                <span class="food_detail">
                  Our Sourcing & Development team support dedicatedly to our
                  Export and Import team of our organization. Mrs Sonam Bane is
                  working in this department since year 2017. She is assisting
                  sales department, in efficiently contacting potential
                  customers and existing companies from our database to
                  establish rapport and researching of new companies /
                  individuals to find new opportunities to develop business
                  performance.
                </span>
              </div>
            </div>
          </div>
          <h2 class="text-red">Administration</h2>
          <div class="row contact-box mb-5">
            <div class="col-md-2 col-sm-12 col-12">
              <img
                src="assets/images/Shazia-Khan-photo-(1).jpg"
                alt=""
                class="mt-5"
              />
            </div>
            <div class="col-md-10 col-sm-12 col-12 p-10">
              <div class="food_tile">
                <span class="food_name">Ms. Shazia Khan</span>
                <div class="mb-3">
                  <h6>
                    <i class="ti-home"></i>&nbsp;
                    &nbsp;Department:Administration
                  </h6>
                  <h6>
                    <i class="ti-email"></i>&nbsp; &nbsp;admin@maroosteels.com
                  </h6>
                  <h6>
                    <i class="ti-mobile"></i>&nbsp; &nbsp;Tel: +91 22 40321800,
                    <br /> &nbsp;+91 9820637660
                  </h6>
                </div>
                <span class="food_detail">
                  Ms Shazia Khan has been handling our administration department
                  since year 2018 in a very effective & productive manner with
                  capabilities of multitasking & ability to prioritise the
                  tasks. She is one of the key person between all our internal
                  departments coordinating with each & everyone in our
                  organisation as well as the relative vendors involved. She is
                  passionate about helping find solutions to any challenges.
                </span>
              </div>
            </div>
                  </div> */}
        </div>
      </section>
      <div class="section mb-11">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14996331.854282739!2d75.829455!3d23.4166416!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1e949bdb36d%3A0x6fd0f95db6437a93!2sMaroo%20Steels%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1709795353424!5m2!1sen!2sin"
          width="100%"
          height="600px"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUS;
