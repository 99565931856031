import React, { useEffect, useState } from "react";

const Carrers = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
          const url=process.env.REACT_APP_API_KEY
          const response = await fetch(`${url}/web-carrers-get`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div
        class=" page_title_light p-40"
        style={{
          background:
            "linear-gradient(0deg, rgba(39,179,203,0.5958381887911415) 0%, rgba(15,125,199,1) 75%, rgb(39,179,203))",
        }}
      >
        <div class="container ">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="page-title">
                <h1>Careers</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="blog_post">
                <div class="blog_content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].content,
                  }}
                />
                  {/* <div class="blog_text">
                    <p>
                      MAROO STEELS PVT LTD follows a 'hire-as-you-go' policy.You
                      can directly upload your resume using this section; and if
                      we feel you can be part of this growth story, we will get
                      in touch with you.Alternatively, you can also send your CV
                      to -{" "}
                      <a href="mailto:Vacancies.maroosteels@gmail.com.">
                        Vacancies.maroosteels@gmail.com.
                      </a>
                      <br />
                      <br />
                      For more on MAROO STEELS PVT LTD, please read the About Us
                      section.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrers;
