import React, { useEffect, useState } from "react";

const CompanyProfile = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${url}/web-companyprofile-get`);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const folderName = "company_profile";

  const main_image = `${imageKey}/${folderName}/${companyData?.data[0].main_content_image}`;
  const import_image = `${imageKey}/${folderName}/${companyData?.data[0].import_image}`;
  const vision_image = `${imageKey}/${folderName}/${companyData?.data[0].vision_image}`;
  const mission_image = `${imageKey}/${folderName}/${companyData?.data[0].mission_image}`;
  const export_image = `${imageKey}/${folderName}/${companyData?.data[0].export_image}`;

  return (
    <div>
      {" "}
      <div
        className=" page_title_light p-40"
        style={{
          background: "rgb(39, 179, 203)",
          background:
            "linear-gradient(0deg, rgba(39, 179, 203, 0.5958381887911415) 0%, rgba(15, 125, 199, 1) 75%)",
        }}
      >
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-title">
                <h1>Company Profile</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container py-5">
          <div className="row text-center">
            <div className="col-md-12 ">
              <div className="blog_heading">
                <h4 className=" mb-4">
                  {/* Thank you for visiting Maroo Steels Private Limited! */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: companyData?.data[0].main_header,
                    }}
                  />
                  {/* {} */}
                </h4>
              </div>
              {/* <h5 className="mb-5">
                We extend a warm welcome to the 'World of Steel' where you can
                find comprehensive sourcing solutions for all your steel
                requirements.
              </h5> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-10">
              <iframe
                src="https://docs.google.com/presentation/d/e/2PACX-1vTrORtHZKCbmgt3Bzp-LQHB0yfpBET-qPWmQRV8-0FfBVGN0K44dJpiwR0fD4BYBw/embed?start=true&loop=false&delayms=3000"
                frameborder="0"
                width="800"
                height="500"
                allowFullScreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
              ></iframe>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-4 ">
            <div className="item mt-5 ">
              {main_image && <img src={main_image} alt="blog_img3" />}
            </div>

            {/* <div className="item mt-5 ">
              <img
                src={require(`file:///E:/React-js/New-Project/backend/maroo_web/src/uploads/company_profile/main_content_image-1711790382212-278010866`)}
                alt="blog_img3"
              />
            </div> */}
          </div>
          <div className="col-md-8">
            <div className="blog_content">
              <div className="blog_text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].main_content,
                  }}
                />
                {/* <p>
                  {" "}
                  Maroo Steels Private Limited, established in 1956, is a
                  trusted steel import-export indenting agent based in Mumbai,
                  India, boasting a rich heritage spanning three generations. We
                  specialize in the sourcing of various steel products from
                  worldwide steel mills both in India and Overseas.
                </p>
                <p>
                  {" "}
                  We have cultivated extensive relationships with all steel
                  mills in India and abroad, enabling us to specialize in
                  procuring good quality steel at competitive prices. Our
                  comprehensive services include assisting Overseas clients in
                  importing their required steel products from Indian steel
                  mills and aiding overseas clients in procuring from Overseas
                  mills. Furthermore, we provide end-to-end support from
                  procurement to shipment documentation and ensure timely
                  material delivery. We also prioritize quality assurance by
                  dispatching technical experts to the plant if required,
                  guaranteeing the quality of the steel and ensuring a seamless
                  experience for our valued customers.
                </p>
                <p>
                  {" "}
                  With a monthly export volume of approximately 10,000 metric
                  tons of steel from Indian mills to our 300+ customers spanning
                  95+ countries, we are dedicated to upholding the highest
                  standards of integrity, reliability, and customer satisfaction
                  in the global steel industry.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg_gray">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="blog_heading">
                <h4 className=" heading1">Export</h4>
              </div>
              {/* <ol className="p-10">
                <li> Our Current exports are about 10,000MT/month.</li>
                <li> About 90% of the same are from India.</li>
                <li>
                  The major share of coated steel products from India goes for
                  exports.
                </li>
              </ol>
              <br />
              <b>We are active in exporting the following steel items:</b>
              <ol className="p-10">
                <li>Hot Dipped Galvanized coils / sheets</li>
                <li>Aluzinc coils / sheets</li>
                <li>
                  Pre-painted Galvanized, Pre-Painted aluzinc in coils / sheets
                </li>
                <li>Zinc Aluminium Magnesium Coils</li>
                <li>Cold rolled coils/sheets</li>
                <li>Hot-Rolled Coils/sheets/plates</li>
                <li>
                  Wire rods in Coils in Low Carbon/High Carbon & Alloy Steel
                  Grades
                </li>
                <li>
                  Hot Rolled Rounds bars in all carbon and alloy steel grades
                </li>
                <li>Electrical Steel Coils CRNGO</li>
                <li>Electrolytic Tin Plates in coils/sheets</li>
              </ol> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].export_content,
                }}
              />
            </div>
            <div className="col-md-5">
              <div className="single_post">
                <div className="blog_img ">
                  {/* <div className="item">
                    <img src="assets/images/c2.png" alt="blog_img3" />
                  </div> */}
                  {export_image && (
                    <img src={`${export_image}`} alt="blog_img3" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="single_post">
                <div className="blog_img">
                  <div className="item">
                    {/* <img src="assets/images/c3.png" alt="blog_img3" /> */}
                    {import_image && (
                      <img src={`${import_image}`} alt="blog_img3" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="blog_heading">
                <h4 className=" heading2">Import</h4>
              </div>
              <div className="blog_content">
                {/* <div className="blog_text"> */}
                {/* <b>
                    {" "}
                    We are active in importing the following steel products:
                  </b>
                  <ol className="p-10">
                    <li>
                      {" "}
                      Hot rolled steel rounds bars in carbon and alloy steel
                      grades
                    </li>
                    <li> Forged round bars in carbon and alloy steel grades</li>
                    <li>Hot Rolled Steel Coils / Sheets / Plates and Strips</li>
                    <li>Cold Rolled Coils / Sheets / Strips</li>
                    <li>Pre-painted Coils / sheets and strips</li>
                    <li> High Manganese plates </li>
                    <li>Billets/ Wire rods in Coils</li>
                    <li>
                      Electrical Steel Coils - Cold Rolled Non Grain Oriented
                      (CRNGO) / Cold Rolle Grain Oriented (CRNO)
                    </li>
                    <li>Electrolytic Tin Plates in coils/sheets</li>
                    <li>Seamless Pipes - Mainly Ball Bearing Grade</li>
                  </ol> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].import_content,
                  }}
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
            <div className="vission-mission">
              <h4 className="mb-3">Vission</h4>
              {/* <img src="assets/images/vision.png" /> */}
              {vision_image && <img src={`${vision_image}`} alt="blog_img3" />}
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].vision_content,
                }}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
            <div className="vission-mission">
              <h4 className="mb-3">Mission</h4>
              {/* <img src="assets/images/target.png" /> */}
              {mission_image && (
                <img src={`${mission_image}`} alt="blog_img3" />
              )}
              <div
                style={{ textAlign: "start" }}
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].mission_content,
                }}
              />

              {/* <ul className="blog_meta text-left mt-2"> */}
              {/* <li>
                  <i className="ti-check-box design1"></i>To add significant
                  value and services to our overseas customers and principals by
                  using our good connections with all the steel suppliers and
                  buyers in India.
                </li>
                <li>
                  <i className="ti-check-box design1"></i>To be always
                  customer's first choice for services.
                </li>
                <li>
                  <i className="ti-check-box design1"></i>To provide efficient
                  and cost effective services.
                </li>
                <li>
                  <i className="ti-check-box design1"></i>To become the leading
                  company in our area of business.
                </li>
                <li>
                  <i className="ti-check-box design1"></i>To provide quality
                  products & service through continuous improvement processes.
                </li>
                <li>
                  <i className="ti-check-box design1"></i>To ensure a best
                  return of investment.
                </li>
                <li>
                  <i className="ti-check-box design1"></i>To conduct incident
                  free operations.
                </li> */}

              {/* </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className=" mb-3">
          {" "}
          <a
            href="https://www.youtube.com/watch?v=d4AjBLTD_TQ"
            target="_blank"
            type="submit"
            className="btn btn-danger btn-md "
          >
            Download Company PPT
          </a>
        </div>
        <div className=" mb-3">
          {" "}
          <a href="/assets/pdf/Catalogue.pdf" target="_blank" type="submit" className="btn btn-info btn-md bg_blue">
            Download Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
