import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  useEffect(() => {
    // Google Translate script settings
    window.gtranslateSettings = {
      default_language: "en",
      languages: [
        "en",
        "fr",
        "de",
        "it",
        "es",
        "pt",
        "zh-CN",
        "ja",
        "ru",
        "ar",
        "hr",
        "cs",
        "nl",
        "et",
        "fi",
        "el",
        "hu",
        "ga",
        "lv",
        "lt",
        "mt",
        "pl",
        "ro",
        "sk",
        "sl",
        "sv",
      ],
      wrapper_selector: ".gtranslate_wrapper",
      flag_size: 24,
      switcher_horizontal_position: "right",
      switcher_vertical_position: "top",
    };

    // Load Google Translate script
    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/dwf.js";
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <header className="header_wrap dark_skin fixed-top">
      <div className="top-header bg_dark light_skin">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="flex-container">
                <div className="a1">
                  <a
                    href="mailto:trading@maroosteels.com"
                    data-toggle="modal"
                    data-target="#SignUp"
                  >
                    <i className="ti-email text_white"></i>{" "}
                    trading@maroosteels.com
                  </a>
                </div>
                &nbsp;
                <div className="a2">
                  {" "}
                  <a href="#" data-toggle="modal" data-target="#Login">
                  <i class="bi bi-telephone-forward"></i>  +91-22-40321800
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid border-b">
        <div className="container ">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="/">
              <img
                className="logo_light"
                src="assets/images/logo_white.png"
                alt="logo"
              />
              <img
                className="logo_dark"
                src="assets/images/logo_dark.png"
                width="250px"
                alt="logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {" "}
              <span className="ion-android-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="dropdown">
                  <a className="nav-link nav_item active" href="/">
                    <Link to="/"> HOME</Link>
                  </a>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle nav-link"
                    data-toggle="dropdown"
                    href="#"
                  >
                    ABOUT US
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/company-profile"
                        >
                          
                          <Link to="/company-profile"> COMPANY PROFILE</Link>
                        </a>
                      </li>

                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/chairman-notes"
                        >
                          <Link to="/chairman-notes"> CEO NOTE</Link>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/current-business"
                        >
                          <Link to="/current-business"> CURRENT BUSINESS</Link>
                        </a>
                      </li>

                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/history"
                        >
                          <Link to="/history"> HISTORY</Link>
                        </a>
                      </li>

                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/team"
                        >
                          <Link to="/team"> TEAM</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle nav-link"
                    data-toggle="dropdown"
                    href="#"
                  >
                    PRODUCTS & SERVICES
                  </a>
                  <div className="dropdown-menu dropdown-reverse">
                    <ul>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/import"
                        >
                          <Link to="/import"> IMPORTS</Link>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/export"
                        >
                          <Link to="/export"> EXPORTS</Link>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          href="/services"
                        >
                          <Link to="/services"> SERVICES</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle nav-link"
                    data-toggle="dropdown"
                    href="#"
                  >
                    GALLERY
                  </a>
                  <div className="dropdown-menu dropdown-reverse">
                    <ul>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/gallery"
                        >
                          <Link to="/gallery">PHOTO GALLERY</Link>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/videos"
                        >
                          {/* VIDEOS */}
                          <Link to="/videos">VIDEOS</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle nav-link"
                    data-toggle="dropdown"
                    href="#"
                  >
                    CONTACT US
                  </a>
                  <div className="dropdown-menu dropdown-reverse">
                    <ul>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/contact-us"
                        >
                          <Link to="/contact-us"> CONTACT US</Link>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/support"
                        >
                          <Link to="/support"> SUPPORT</Link>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          // href="/careers"
                        >
                          <Link to="/careers"> CAREERS</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                {/* <li>
                  <a className="nav-link nav_item" href="/enquiry">
                    ENQUIRY
                  </a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <div className="gtranslate_wrapper"></div>
    </header>
  );
};

export default Header;
