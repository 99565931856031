import React, { useEffect, useState } from "react";

const Services = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url=process.env.REACT_APP_API_KEY
        const response = await fetch(`${url}/web-services-get`);
        // const response = await fetch("http://localhost:3000/api/getWEBService");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div
        class=" page_title_light p-40"
        style={{
          background:
            "linear-gradient(0deg, rgba(39,179,203,0.5958381887911415) 0%, rgba(15,125,199,1) 75%, rgb(39,179,203))",
        }}
      >
        <div class="container ">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="page-title">
                <h1>Services</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
            <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].content,
                }}/>
              {/* <div class="blog_heading">
              </div>
              <p class="mb-4">
                MAROO STEELS PVT LTD has been in the steel business for over
                half a century. As a steel sourcing agent, we offer exceptional
                value by partnering with customers to plan for their near and
                long-term needs. We work closely with our steel suppliers to
                maintain a consistent supply of materials at a fair price.
                Collaborating with steel producers also enables us to
                investigate new strategies for improving quality.
              </p>

              <p>
                {" "}
                We mainly deal with first-class mills. Prices from any one
                specific mill may not be always competitive. We guide clients as
                to which mill is looking for orders and which mill is offering
                competitive prices for specific materials, sizes, coatings, etc.
                at the time of your order. We believe in providing clients with
                the best market price from all suppliers and constantly updating
                clients with market information.{" "}
              </p>
              <p>
                {" "}
                We update customers by providing them with information from
                mills such as pricing and strategies, making it easier for
                customers to make informed decisions. If a sudden special offer
                becomes available for exports from a mill, we inform our clients
                so they can take advantage of it. We also follow up to ensure
                that client orders are processed and shipped quickly and safely
                on time.
              </p>
              <p>
                {" "}
                In essence, we act as an interface between clients and
                vendors/suppliers. We keep clients updated on the status of
                their orders and address any inquiries they may have.
              </p>

              <p>
                {" "}
                Additionally, we work as a buying partner. It will be like
                international customers having their own branch office in India.
                We assist customers by circulating their inquiries, negotiating
                with mills on their behalf in booking orders, and providing
                timely updates during the production and shipment of their
                orders. We take care of the customer's order from the inquiry
                stage until they receive the material.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
