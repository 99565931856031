import React, { useEffect, useState } from "react";

const Videos = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${url}/web-video-get`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div
        class=" page_title_light p-40"
        sstyle={{
          background:
            "linear-gradient(0deg, rgba(39,179,203,0.5958381887911415) 0%, rgba(15,125,199,1) 75%, rgb(39,179,203))",
        }}
      >
        <div class="container ">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="page-title">
                <h1>Videos</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section tranding_post pb_70">
        <div class="container">
          <div class="row">
            {/* {companyData &&
              companyData?.data?.map((data) => {
                return (
                  <div class="col-md-6">
                    <iframe
                      width="453"
                      height="264"
                      // const main_image = `${imageKey}//${companyData?.imgae_folder}/${companyData?.data[0].main_content_image}`;
                      // src="https://www.youtube.com/embed/bpQvapWFv_Y"
                      // src={`${imageKey}/video/${data?.image}`}
                      src=" https://www.youtube.com/embed/bpQvapWFv_Y"
                      title="The production process of a steel casting"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    <iframe
                      width="453"
                      height="264"
                      // const main_image = `${imageKey}//${companyData?.imgae_folder}/${companyData?.data[0].main_content_image}`;
                      // src="https://www.youtube.com/embed/bpQvapWFv_Y"
                      // src={`${imageKey}/video/${data?.image}`}
                      src="https://www.youtube.com/embed/Ea_7Rnd8BTM"
                      title="The production process of a steel casting"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    
                  </div>
                );

                 <div class="col-md-6">
                    <iframe
                      width="453"
                      height="264"
                      // const main_image = `${imageKey}//${companyData?.imgae_folder}/${companyData?.data[0].main_content_image}`;
                      // src="https://www.youtube.com/embed/bpQvapWFv_Y"
                      // src={`${imageKey}/video/${data?.image}`}
                      src=" https://www.youtube.com/embed/bpQvapWFv_Y"
                      title="The production process of a steel casting"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    <iframe
                      width="453"
                      height="264"
                      // const main_image = `${imageKey}//${companyData?.imgae_folder}/${companyData?.data[0].main_content_image}`;
                      // src="https://www.youtube.com/embed/bpQvapWFv_Y"
                      // src={`${imageKey}/video/${data?.image}`}
                      src="https://www.youtube.com/embed/Ea_7Rnd8BTM"
                      title="The production process of a steel casting"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    
                  </div>
              })} */}
            {/* <div class="col-md-6">
              <iframe
                width="453"
                height="264"
                src="https://www.youtube.com/embed/Ea_7Rnd8BTM"
                title="Steelmaking"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div> */}
            <div class="col-md-6">
              <iframe
                width="453"
                height="264"
                // const main_image = `${imageKey}//${companyData?.imgae_folder}/${companyData?.data[0].main_content_image}`;
                // src="https://www.youtube.com/embed/bpQvapWFv_Y"
                // src={`${imageKey}/video/${data?.image}`}
                src=" https://www.youtube.com/embed/bpQvapWFv_Y"
                title="The production process of a steel casting"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div class="col-md-6">
              <iframe
                width="453"
                height="264"
                // const main_image = `${imageKey}//${companyData?.imgae_folder}/${companyData?.data[0].main_content_image}`;
                // src="https://www.youtube.com/embed/bpQvapWFv_Y"
                // src={`${imageKey}/video/${data?.image}`}
                src="https://www.youtube.com/embed/Ea_7Rnd8BTM"
                title="The production process of a steel casting"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;
