import React, { useEffect, useState } from "react";

const CurrentBuisness = () => {
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url=process.env.REACT_APP_API_KEY
        const response = await fetch(`${url}/web-currentbusiness-get`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div
        className="page_title_light p-40"
        style={{
          background: "rgb(39, 179, 203)",
          background:
            "linear-gradient(0deg, rgba(39, 179, 203, 0.5958381887911415) 0%, rgba(15, 125, 199, 1) 75%)",
        }}
      >
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-title">
                <h1>Current Business</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="blog_post">
                <div className="blog_content">
                  <div className="blog_text">
                    {/* <p>
                      Currently all together we are doing about 12,500 metric
                      tons per month business for exports from India. The major
                      share of which goes for exports of coated steels products
                      from India. In case of imports into India, the quantity is
                      approximately 2,000 metric tons mostly for secondary steel
                      products.
                    </p> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: companyData?.data[0].content,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentBuisness;
