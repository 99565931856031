import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Export = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exportBannerData, setExportBannerData] = useState();

  const fetchData = async () => {
    try {
      const url = process.env.REACT_APP_API_KEY;
      const response = await fetch(`${url}/web-exportdata-get`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data) {
        setCompanyData(data);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataBannerData = async () => {
      try {
        const url = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${url}/web-exportbanner-get`);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setExportBannerData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchDataBannerData();
  }, []);

  const image_folder = "exports_data";
  const aluminium_image = `${imageKey}/${image_folder}/${companyData?.data[0].aluminium_image}`;
  const aluminiumn_coil_image = `${imageKey}/${image_folder}/${companyData?.data[0].aluminiumn_coil_image}`;
  const aluzinc_steel_image = `${imageKey}/${image_folder}/${companyData?.data[0].aluzinc_steel_image}`;
  const bare_aluminium_image = `${imageKey}/${image_folder}/${companyData?.data[0].bare_aluminium_image}`;
  const cold_rolled_image = `${imageKey}/${image_folder}/${companyData?.data[0].cold_rolled_image}`;
  const color_coated_image = `${imageKey}/${image_folder}/${companyData?.data[0].color_coated_image}`;
  const ferro_alloys_image = `${imageKey}/${image_folder}/${companyData?.data[0].ferro_alloys_image}`;
  const hot_dipped_image = `${imageKey}/${image_folder}/${companyData?.data[0].hot_dipped_image}`;
  const hot_rolled_image = `${imageKey}/${image_folder}/${companyData?.data[0].hot_rolled_image}`;
  const hot_rolled_round_image = `${imageKey}/${image_folder}/${companyData?.data[0].hot_rolled_round_image}`;
  const pipe_products_image = `${imageKey}/${image_folder}/${companyData?.data[0].pipe_products_image}`;
  const prepainted_image = `${imageKey}/${image_folder}/${companyData?.data[0].prepainted_image}`;
  const stainless_steel_image = `${imageKey}/${image_folder}/${companyData?.data[0].stainless_steel_image}`;
  const structural_steel_image = `${imageKey}/${image_folder}/${companyData?.data[0].structural_steel_image}`;
  const wire_products_image = `${imageKey}/${image_folder}/${companyData?.data[0].wire_products_image}`;
  const wire_rods_image = `${imageKey}/${image_folder}/${companyData?.data[0].wire_rods_image}`;
  const zinc_aluminum_image = `${imageKey}/${image_folder}/${companyData?.data[0].zinc_aluminum_image}`;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <div>
        {/* <div class="banner_section ">
          <div
            class="carousel_slider owl-carousel"
            data-loop="true"
            data-items="1"
            data-nav="true"
            data-autoplay="true"
            data-smart-speed="100"
          >
            {exportBannerData &&
              exportBannerData.data.length > 0 &&
              exportBannerData?.data?.map((data) => {
                return (
                  <div
                    className="item active background_bg"
                    style={{
                      backgroundImage: `url(${imageKey}/exports_banner/${data?.image})`,
                      height: "300px",
                    }}
                  >
                    <div className="banner_slide_content">
                      <div className="container text-center">
                        <div className="row">
                          <div className="col-xl-12 col-md-8 col-sm-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
           
          </div>
        </div> */}

        <div className="banner_section">
          {exportBannerData && exportBannerData !== null && (
            <Slider {...settings}>
              {exportBannerData?.data?.map((data) => (
                <div
                  key={data.id} // Assuming each banner data has a unique ID
                  className="item active background_bg"
                  style={{
                    // backgroundImage: `url(${imageKey}/import_banner/${data.image})`,
                    // height: "300px",
                  }}
                >
                  <img height={"300px"} width={"100%"} src={`${imageKey}/exports_banner/${data.image}`} />
                  <div className="banner_slide_content">
                    <div className="container text-center">
                      <div className="row">
                        <div className="col-xl-12 col-md-8 col-sm-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>

        <div class="text-black p-40 mt-double">
          <div class="container ">
            <div class="row text-center">
              <div class="col-md-12">
                <div class="mt-5">
                  <h1>Exports</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <div class="container">
            <div class="row mt-3">
              <div class="col-md-8">
                <h4 class="heading2">Hot Rolled Steel Coils, Sheets, Plates</h4>
                {/* <h5>Supply Condition: </h5>
                          <h6>Quality/Industry:</h6>
                          <ul class="blog_meta">
                              <li><i class="ti-check-box design1"></i>For Coils and Sheets - Cold Rolling, Pipe making, Automobile High Tensile, Corten steel, LPG Quality, API Grades etc.</li>
                              <li><i class="ti-check-box design1"></i>For Plates - Structural, Ship-building, Pressure Vessel, High Tensile, etc.</li>
                          </ul><br />
                          <h5>Available Specification:</h5>
                          <ul class="blog_meta">
                              <li><i class="ti-check-box design1"></i>As per BS/DIN/ASTM/JIS and equivalent.</li>
                              <li><i class="ti-check-box design1"></i>Coils and Sheets - 1mm to 16mm</li>
                              <li><i class="ti-check-box design1"></i>Plates - 8mm to 150mm</li>
                          </ul><br />
                          <h5>Width:</h5>
                          <ul class="blog_meta">
                              <li><i class="ti-check-box design1"></i>HRC: 2000 mm max.</li>
                              <li><i class="ti-check-box design1"></i>HRP: Upto 4900 mm</li>
                              <li><i class="ti-check-box design1"></i>HRP Length: upto 25 mtr.</li>
                          </ul><br />
                          <h5>Coil Wt:</h5>
                          <ul class="blog_meta">
                              <li><i class="ti-check-box design1"></i>18 kg per mm width, max. 30 MT</li>
                          </ul><br /> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].hot_rolled,
                  }}
                />
              </div>
              <div className="col-md-4">
                <img src={hot_rolled_image} />
              </div>
            </div>
          </div>
        </div>

        <div class="section bg_gray">
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <h4 class="heading2">
                  Cold Rolled Steel Coils and Sheets in full Hard and Soft
                  Quality
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].cold_rolled,
                  }}
                />
                {/* <h5>Supply Condition: </h5>
                <ul class="blog_meta">
                  <li>
                    <i class="ti-check-box design1"></i>Full Hard, Soft, Deep
                    drawing, Extra deep drawing, Structural & Enamelling Grade
                  </li>
                </ul>
                <br />
                <h5>Available Specification:</h5>
                <ul class="blog_meta">
                  <li>
                    <i class="ti-check-box design1"></i>EN/ASTM/JIS and
                    equivalent.
                  </li>
                </ul>
                <br />
                <h5>Thickness:</h5>
                <ul class="blog_meta">
                  <li>
                    <i class="ti-check-box design1"></i>CRFH: 0.12mm to 1.5mm
                  </li>
                  <li>
                    <i class="ti-check-box design1"></i>CRCA: 0.25mm to 3mm
                  </li>
                </ul>
                <br />
                <h5>Width:</h5>
                <ul class="blog_meta">
                  <li>
                    <i class="ti-check-box design1"></i>CRFH: 1250mm max.
                  </li>
                  <li>
                    <i class="ti-check-box design1"></i>CRCA: 1650mm max. - 0.50
                    mm & UP for 0.25 to 0.50 Width max.1250
                  </li>
                </ul>
                <br />
                <h5>Coil Wt:</h5>
                <ul class="blog_meta">
                  <li>
                    <i class="ti-check-box design1"></i>Container: 8MT max.
                  </li>
                  <li>
                    <i class="ti-check-box design1"></i>Bulk: 30 MT max.
                  </li>
                </ul> */}
                <br />
              </div>
              <div className="col-md-4">
                <img src={cold_rolled_image} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="row ">
            <div class="col-md-8">
              <h4 class=" heading2">
                Hot Dipped Galvanized Steel Coils and Sheets{" "}
              </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].hot_dipped,
                }}
              />
              {/* <h5>Supply Condition: </h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Non Skin-Passed
                  (regular/small spangle) and Skin Passed (suppressed/zero
                  spangle).
                </li>
              </ul>
              <br />
              <h5>Available Specification:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>ASTM/JIS/EN and
                  equivalent. In full hard, semi hard and soft quality
                  (commercial grade, lock forming grade and structural grade).
                </li>
              </ul>
              <br />
              <h5>Zinc Coating:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>90 gsm to 275 gsm
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Can also offer Heavy
                  Coating up to 600 gsm in specific end-use like Grain Silo.
                </li>
              </ul>
              <br />
              <h5>Thickness:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Hard Coils - 0.14mm to
                  0.70mm
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Soft Coils & Structural
                  grades - 0.23mm to 3.00mm
                </li>
              </ul>
              <br />
              <h5>Width:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Hard - 1250mm max.
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Soft - 1550mm max. (0.50mm
                  and above).
                </li>
              </ul>
              <br />
              <h5>Coil Wt:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Container: 8MT max.
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Bulk : 30MT max.
                </li>
              </ul>
              <br />
              <h5>Surface Finish:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Bright Dull and Matt
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Bulk : 30MT max.
                </li>
              </ul> */}
              <br />
            </div>
            <div className="col-md-4">
              <img src={hot_dipped_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section bg_gray">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h4 class=" heading2">Aluzinc steel Coils and Sheets</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].aluzinc_steel,
                }}
              />
              {/* <h5>Supply Condition: </h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>ASTM/JIS/EN and
                  equivalent. In full hard, semi hard and soft quality
                  (commercial grade, lock forming grade and structural grade).
                </li>
              </ul>
              <br />
              <h5>AL + Zinc Coating:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>50 AZM to 185 AZM
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Thickness: Hard Coils -
                  0.18mm to 0.70mm
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Soft Coils & Structural
                  grades - 0.23mm to 3mm
                </li>
              </ul>
              <br />
              <h5>Width:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>1550mm max.
                </li>
              </ul>
              <br />
              <h5>Coil Wt:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Container: 8MT max.
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Bulk : 30MT max.
                </li>
              </ul>
              <br />*/}
              {/* <h5>Surface Finish:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Anti- Finger print
                </li>
              </ul>  */}
            </div>
            <div className="col-md-4">
              <img src={aluzinc_steel_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h4 class=" heading2">Zinc Aluminum Magnesium ZAM (Zn+Al+Mg) Coils and Sheets</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].zinc_aluminum,
                }}
              />
              {/* <h5>Supply Condition: </h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>ASTM/JIS/EN and
                  equivalent. In full hard, semi hard and soft quality
                  (commercial grade, lock forming grade and structural grade).
                </li>
              </ul>
              <br />
              <h5>AL + Zinc Coating:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>50 AZM to 185 AZM
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Thickness: Hard Coils -
                  0.18mm to 0.70mm
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Soft Coils & Structural
                  grades - 0.23mm to 3mm
                </li>
              </ul>
              <br />
              <h5>Width:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>1550mm max.
                </li>
              </ul>
              <br />
              <h5>Coil Wt:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Container: 8MT max.
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Bulk : 30MT max.
                </li>
              </ul>
              <br />*/}
              {/* <h5>Surface Finish:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Anti- Finger print
                </li>
              </ul>  */}
            </div>
            <div className="col-md-4">
              <img src={zinc_aluminum_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section bg-gray">
        <div class="container">
          <div class="row ">
            <div class="col-md-8">
              <h4 class=" heading2">
                Prepainted / Colour Coated Steel Coils and Sheets{" "}
              </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].prepainted,
                }}
              />
              {/* <h5>Supply Condition: </h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>ASTM/JIS/EN and
                  equivalent. In full hard, semi-hard and soft quality.
                </li>
              </ul>
              <br />
              <h5>Available Specification:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>EN/ASTM/JIS and
                  equivalent.
                </li>
              </ul>
              <br />
              <h5>Zinc Coating:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Base Material - Galvanized
                  Coils, Galvalume Coils, Aluminium Coils
                </li>
              </ul>
              <br />
              <h5>Thickness:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Galvanized & Galvalume
                  based - 0.18mm to 1.6mm
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Aluminium based - 0.30mm
                  to 1.5mm
                </li>
              </ul>
              <br />
              <h5>Width:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>1550mm max.
                </li>
              </ul>
              <br />
              <h5>Coil Wt:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Container: 8MT max. (max.
                  4 with Guard Film).
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Bulk : 12MT max.
                </li>
              </ul>
              <br />
              <h5>Coatings:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Top Coats - 5 micron
                  primer + 20 micron colour
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Back Coat - 5 micron
                  primer - 7 micron primer
                </li>
              </ul>
              <h5>Colors:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>All Colours available.
                  Colour shades have to be matched.
                </li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={prepainted_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section ">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h4 class=" heading2">Color Coated Aluminium Coil</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].color_coated,
                }}
              />
              {/* <h5>Alloy :</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>AA 3105 , 8011, 1050,
                  1100, 1200, 3003, 5005
                </li>
              </ul>
              <br />
              <h5>Thickness:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>30mm-0.90mm
                </li>
              </ul>
              <br />
              <h5>Width:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>1280mm Max.
                </li>
                <li>T/Coat: 5 Mic Primer +18-20 Mic RMP Coat</li>
                <li>B/Coat: 5-7 Mic -PU -RAL 7035</li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={color_coated_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section bg-gray">
        <div class="container">
          <div class="row ">
            <div class="col-md-8">
              <h4 class=" heading2">
                Bare Aluminium and Color Coated Aluminium - Embossed Coil
              </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].bare_aluminium,
                }}
              />
              {/* <h5>Alloy :</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>AA 3105 , 1050, 1100,
                  1200, 5005, 8011
                </li>
              </ul>
              <br />
              <h5>Thickness:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>23mm - 1.00mm
                </li>
              </ul>
              <br />
              <h5>Width:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>1250mm
                </li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={bare_aluminium_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section ">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h4 class=" heading2">ALUMINIUM Coil - Mill finish</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].aluminiumn_coil,
                }}
              />
              {/* <h5>Aluminium Coil:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>AA 3105 , 1050, 1100,
                  1200, 5005, 8011
                </li>
              </ul>
              <br />
              <h5>Thickness:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>30mm -2.00mm
                </li>
              </ul>
              <br />
              <h5>Width:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>1280mm max.
                </li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={aluminiumn_coil_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section bg-gray">
        <div class="container">
          <div class="row ">
            <div class="col-md-8">
              <h4 class=" heading2">Aluminium Chequered Plate</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].aluminium,
                }}
              />
              {/* <h5>Alloy :</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>AA 3105
                </li>
              </ul>
              <br />
              <h5>Thickness:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>40mm - 4.00mm
                </li>
              </ul>
              <br />
              <h5>Width:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>1280mm max.
                </li>
              </ul>
              <br />
              <h5>Height of the elevated portion:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>30-35% of Thickness
                </li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={aluminium_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section ">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h4 class=" heading2">Wire Rods in Coils</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].wire_rods,
                }}
              />
              {/* <h5>Available Specification:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>All Types of Carbon (Low,
                  Medium & High), Alloy and Special Steel Grades.
                </li>
              </ul>
              <br />
              <h5>Thickness:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>5mm to 24mm
                </li>
              </ul>
              <br />
              <h5>Width:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Upto 2.2 MT
                </li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={wire_rods_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section bg-gray">
        <div class="container">
          <div class="row ">
            <div class="col-md-8">
              <h4 class=" heading2">Wire Products</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].wire_products,
                }}
              />
              {/* <h6>
                GI Wires /Stay Wires/Barbed Wires/Guy Wire/Earth Wire/ACSR Core
                Wire & Strands/AAC & ACSR Conductors/Cable Armoring Wire/PC Wire
                & Strand/Chain Link Fence/Shutter Spring Wire/Spring Steel
                Wire/Mild Steel & High Carbon Black Wire/PVC Coated Wires /Zinc
                Aluminium Alloy Coated Wire
              </h6>
              <h5>Dia:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>4 to 8 mm
                </li>
              </ul>
              <br />
              <h5>Bundle Weight:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>500 kgs to 1000 kgs
                </li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={wire_products_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section ">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h4 class=" heading2">Structural Steel</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].structural_steel,
                }}
              />
              {/* <h5>Available Specification:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>
                  Angles/Channel/Beams/Columns
                </li>
              </ul>
              <br />
              <h5>Container:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>8mtr max. by 20ft, 11.6mtr
                  max. by 40ft
                </li>
              </ul>
              <br />
              <h5>Bulk:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>12mtr max.
                </li>
              </ul>
              <br />
              <h5>Thickness:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Angles : 50mm to 250mm
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Channels: 75mm to 400mm
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Beams : UB - 203mm X 133mm
                  to 610mm X 229mm
                </li>
              </ul>
              <br />
              <h5>Columns:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>UC - 152mm X 152mm to
                  356mm X 368mm
                </li>
              </ul>
              <br />
              <h5>Length:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>12 mtr
                </li>
              </ul> */}
              <br />
            </div>
            <div className="col-md-4">
              <img src={structural_steel_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section bg-gray">
        <div class="container">
          <div class="row ">
            <div class="col-md-8">
              <h4 class=" heading2">Pipe Products:</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].pipe_products,
                }}
              />
              {/* <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>Black pipes/Hollow
                  Sections/Hot Dipped Galvanised Pipes/Pre Galvanised Pipes -12
                  mm(1/4” ) OD up to 355.9*mm OD (14”);
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Square Hollow sections
                  from 15mm x 15mm up to 300mm x 300mm.
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Rectangular Hollow
                  Sections from 25 x 20 to 400 x 200 mm.
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Wall thickness ranging :
                  0.8mm up to 12 mm.
                </li>
              </ul> */}
            </div>
            <div className="col-md-4">
              <img src={pipe_products_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section ">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h4 class=" heading2">Hot Rolled Round Bars</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].hot_rolled_round,
                }}
              />
              {/* <h5>Dia:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>• 14 to 200 mm
                </li>
              </ul>
              <br />
              <h5>Grades:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>• In all kinds of Carbon,
                  Alloy and Special Steel grades.
                </li>
              </ul>
              <br />
              <h5>Bright bars:</h5>
              <ul class="blog_meta">
                <li>
                  <i class="ti-check-box design1"></i>• 22 mm to 155 mm dia.
                  (Heat Treated bars: Annealed /Quenched & Tempered).
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Hot Rolled Billets / RCS :
                  100, 130, 150 mm
                </li>
                <li>
                  <i class="ti-check-box design1"></i>Hot Rolled Steel Flat Bars
                  : Regular size Thickness 8 to 20 mm and Width 40 to 100 mm.{" "}
                </li>
              </ul>
              <p>We also offer other bigger sizes flats as well.</p> */}
            </div>
            <div className="col-md-4">
              <img src={hot_rolled_round_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section bg-gray">
        <div class="container">
          <div class="row ">
            <div class="col-md-8">
              <h4 class=" heading2">Stainless Steel Flat and long products</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].stainless_steel,
                }}
              />
              {/* <h5>Grade - 200 Series / 300 Series / 400 Series</h5> */}
            </div>
            <div className="col-md-4">
              <img src={stainless_steel_image} />
            </div>
          </div>
        </div>
      </div>
      <div class="section ">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h4 class=" heading2">Ferro Alloys</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: companyData?.data[0].ferro_alloys,
                }}
              />
              {/* <p>
                All kinds of Ferro Alloys including Silico Manganese, Ferro
                Manganese, Ferro Silicon etc.
              </p> */}
            </div>
            <div className="col-md-4">
              <img src={ferro_alloys_image} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Export;
