import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";

const Team = () => {
  const imageKey = process.env.REACT_APP_IMAGE_KEY;
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${url}/web-team-get`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCompanyData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const foder_name = "team";
  const image_1 = `${imageKey}/${foder_name}/${companyData?.data[0].image_1}`;
  const image_2 = `${imageKey}/${foder_name}/${companyData?.data[0].image_2}`;
  const image_3 = `${imageKey}/${foder_name}/${companyData?.data[0].image_3}`;
  const image_4 = `${imageKey}/${foder_name}/${companyData?.data[0].image_4}`;
  const image_5 = `${imageKey}/${foder_name}/${companyData?.data[0].image_5}`;
  const image_6 = `${imageKey}/${foder_name}/${companyData?.data[0].image_6}`;
  return (
    <>
      <div
        class=" page_title_light p-40"
        style={{
          background: "rgb(39, 179, 203)",
          background:
            "linear-gradient(0deg, rgba(39, 179, 203, 0.5958381887911415) 0%, rgba(15, 125, 199, 1) 75%)",
        }}
      >
        <div class="container ">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="page-title">
                <h1>Team</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section banner_blog ">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="blog_content">
                {/* <div class="">
                  <p class="para">
                    The valuable guidance of Mr. Sunil Maroo has helped our
                    organization to evolve from being a start-up in history of
                    Steel Markets to being one of the most successful
                    organizations. His insights of industry experience has
                    helped us understand business technology and management. Our
                    team, with its good teamwork and collaboration, add value to
                    the delivered products and services, and address client
                    needs efficiently. Meet the team behind our mission
                    providing comprehensive services :
                  </p>
                </div> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].content,
                  }}
                />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-4 col-sm-12">
              <img src={image_1 ? image_1 : "assets/images/team1.jpeg"} />
            </div>
            <div class="col-md-4 col-sm-12">
              <img src={image_2 ? image_2 : "assets/images/team.jpeg"} />
            </div>
            <div class="col-md-4 col-sm-12">
              <img src={image_3 ? image_3 : "assets/images/blog_img-11.jpeg"} />
            </div>
          </div>
        </div>
      </div>

      <section class="advertisers-service-sec pt-5 pb-5">
        <div class="container mb-5">
          <div class="row mt-3">
            <div class="col-md-12 text-center">
              <div class="blog_heading">
                <h4 class="text-red">Management</h4>
              </div>
            </div>
          </div>
          <div class="row  row-cols-1 row-cols-sm-1 row-cols-md-3 ">
            <div class="col-md-4 col-sm-12 col-12 mt-5">
              <div class="service-card">
                <div class="text-center">
                  <img
                    class="icon-wrapper1"
                    src={image_4 ? image_4 : "assets/images/img5.jpeg"}
                  />
                </div>
                <h3 class="text-center">{companyData?.data[0].image_4_name}</h3>
                <h6 class="text-center">
                  {companyData?.data[0].image_4_desgination}
                </h6>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].image_4_content,
                  }}
                /> */}
                {/* <p class="mt-3">
                He is the Chairman of MAROO STEELS PVT LTD. His responsibility is to acquire essential feedback from clients on the offers made by his assistants. He looks after development of new clients and personally follows up on claim requests, if any.
            </p> */}
                <div class="text-white">
                  {" "}
                  {/* <a
                    href="contact-us.html"
                    type="submit"
                    class="btn btn-primary btn-sm"
                  >
                    View More
                  </a> */}
                  <Link to="/contact-us" className="btn btn-default btn-sm">
                    {" "}
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 col-12 mt-5">
              <div class="service-card">
                <div class="text-center">
                  <img
                    class="icon-wrapper1"
                    src={
                      image_5
                        ? image_5
                        : "assets/images/Sujit-Pal-Management1.jpg"
                    }
                  />
                </div>
                <h3 class="text-center">{companyData?.data[0].image_5_name}</h3>
                <h6 class="text-center">
                  {companyData?.data[0].image_5_desgination}
                </h6>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].image_5_content,
                  }}
                /> */}
                <div class="text-white">
                  {" "}
                  {/* <a
                    href="contact-us.html"
                    type="submit"
                    class="btn btn-primary btn-sm"
                  >
                    View More
                  </a>*/}
                </div>
                <Link to="/contact-us" className="btn btn-default btn-sm">
                  Read More
                </Link>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 col-12 mt-5">
              <div class="service-card">
                <div class="text-center">
                  <img
                    class="icon-wrapper1"
                    src={
                      image_6
                        ? image_6
                        : "assets/images/Tarrak-Raawwal-photo.jpeg"
                    }
                  />
                </div>
                <h3 class="text-center">{companyData?.data[0].image_6_name}</h3>
                <h6 class="text-center">
                  {companyData?.data[0].image_6_desgination}
                </h6>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: companyData?.data[0].image_6_content,
                  }}
                /> */}
                <br />
                <br />
                <div class="text-white mb-0">
                  {" "}
                  {/* <a
                    href="contact-us.html"
                    type="submit"
                    class="btn btn-primary btn-sm"
                  >
                    View More
                  </a> */}
                  <Link to="/contact-us" className="btn btn-default btn-sm">
                    {" "}
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
