import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer
        className="footer"
        style={{ backgroundImage: "url(./assets/images/footer1.jpg)" }}
      >
        <div className="footer_top ">
          <div className="container pt-10">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-7">
                <div className="widget">
                  <h6 className="widget_title">Contact Us</h6>
                  <ul className="contact_info text_dark">
                    <li>
                      <i className="ti-location-pin"></i>
                      <p>
                        {" "}
                        Maroo Steels Private Limited
                        <br />
                        64, B Wing Mittal Court, Nariman Point Mumbai,
                        Maharashtra 400021 India
                      </p>
                    </li>
                    <li>
                      <i className="ti-email"></i>
                      <a href="mailto:trading@maroosteels.com" target="_blank">
                        trading@maroosteels.com
                      </a>
                    </li>
                    <li>
                    <i class="bi bi-telephone-forward"></i>
                      <p>+91-22-40321800</p>
                    </li>
                  </ul>
                </div>
                <div className="widget">
                  <ul class="widget_social social_icons ">
                    <li>
                      <a href="https://t.me/Sunil_Maroo" target="_blank">
                        <img src="assets/images/telegram.png" width="20px" />
                      </a>
                    </li>
                    <li>
                      <a href="https://chats.viber.com/add?number=919820637667" target="_blank">
                        <img src="assets/images/viber.png" width="20px" />
                      </a>
                    </li>
                    <li>
                      <a href="https://join.skype.com/invite/lOEPfCblXB01" target="_blank">
                        <img src="assets/images/skype.png" width="20px" />
                      </a>
                    </li>
                    <li>
                      <a href="https://api.whatsapp.com/send?phone=919820637667&text=Got%20your%20reference%20from%20your%20*Digital%20Business%20Card*.%20I%20want%20to%20know%20more%20about%20your%20services." target="_blank">
                        <img src="assets/images/whatsapp.png" width="20px" target="_blank" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.kakaocorp.com/page/service/service/KakaoTalk" target="_blank">
                        <img src="assets/images/kakaotalk.png" width="25px" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/sunil-maroo-92213917b/" target="_blank">
                        <img src="assets/images/linkedin.png" width="20px" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-md-8 col-sm-12">
                <div className="widget">
                  <h6 className="widget_title ">Information </h6>
                  <ul className="widget_links">
                    <li>
                      {/* <a href="/careers">Careers</a> */}
                      <Link to="/careers"> Careers</Link>
                    </li>
                    <li>
                      {/* <a href="/chairman-notes">Chairman Note</a>
                       */}
                        <Link to="/chairman-notes"> Ceo Note</Link>
                    </li>
                    <li>
                      {/* <a href="/company-profile">Company Profile</a> */}
                      <Link to="/company-profile"> Company Profile</Link>
                    </li>
                    <li>
                      {/* <a href="/current-business">Current Business</a> */}
                      <Link to="/current-business"> Current Business</Link>
                    </li>
                    <li>
                      {/* <a href="/history">History</a> */}
                      <Link to="/history"> History</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-md-8 col-sm-12">
                <div className="widget">
                  <h6 className="widget_title  "> </h6>
                  <ul className="widget_links pt-20">
                    <li>
                      {/* <a href="/gallery">Photo Gallery</a> */}
                      <Link to="/gallery">Photo Gallery</Link>
                    </li>
                    <li>
                      {/* <a href="/support">Support</a> */}
                      <Link to="/support"> Support</Link>
                    </li>
                    <li>
                      {/* <a href="/team">Team</a> */}
                      <Link to="/team"> Team</Link>
                    </li>
                    <li>
                      {/* <a href="/videos">Videos</a> */}
                      <Link to="/videos">Videos</Link>
                    </li>
                    <li>
                      {/* <a href="/company-profile"></a> */}
                      <Link to="/Company-profile"> Vision Mission</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-md-8 col-sm-12">
                <div className="widget">
                  <h6 className="widget_title ">Services </h6>
                  <ul className="widget_links ">
                    <li>
                      {/* <a href="/import">Import</a> */}
                      <Link to="/import"> Import</Link>
                    </li>
                    <li>
                      {/* <a href="/export">Export</a> */}
                      <Link to="/export"> Export</Link>
                    </li>
                    <li>
                      {/* <a href="/services">Services</a> */}
                      <Link to="/services"> Services</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12">
                <div className="widget">
                  <h6 className="widget_title">Location</h6>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14996331.854282739!2d75.829455!3d23.4166416!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1e949bdb36d%3A0x6fd0f95db6437a93!2sMaroo%20Steels%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1709873198557!5m2!1sen!2sin"
                    width="270"
                    height="220"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bottom_footer border-top-tran "
          style={{ backgroundColor: "#083f87", color: "#fff" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className=" m-0 text-center text-w">
                  Copyright@2024 Maroo Steels | All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="gtranslate_wrapper"></div>
      <div class="icon-bar">
      <a href="https://api.whatsapp.com/send?phone=919820637667" target="_blank" class="facebook"><img src="assets/images/whatsapp.png" width="30px" /></a> 
      <a href="https://chats.viber.com/add?number=919820637667" target="_blank" class="twitter"><img src="assets/images/viber.png" width="30px" /></a> 
      <a href="https://t.me/Sunil_Maroo" target="_blank" class="google"><img src="assets/images/telegram.png" width="30px" /></a> 
      <a href="https://join.skype.com/invite/lOEPfCblXB01" target="_blank" class="linkedin"><img src="assets/images/skype.png" width="30px" /></a>
    </div>
    </>
  );
};

export default Footer;
